(function(window) {
  /**
   * Affixer
   */
  window.affixer = {
    affixables: [],
    affixable: (function() {
      function Affixable(el) {
        this.el = el;

        this.initClasses();
        this.parent = el.parentNode;
        this.topStyle = this.getTopStyle();
        this.top = this.getTop();
        this.maxY = this.getMaxY();
      }

      Affixable.prototype.initClasses = function() {
        if (this.el.classList.contains('affix-bottom')) {
          this.el.classList.remove('affix-bottom');
        }
        if (this.el.classList.contains('affix')) {
          this.el.classList.remove('affix');
        }
      };

      Affixable.prototype.getBottom = function() {
        return this.maxY - this.el.offsetHeight - this.topStyle;
      };

      Affixable.prototype.getMaxY = function() {
        return window.pageYOffset + this.parent.getBoundingClientRect().top + this.parent.offsetHeight - (parseInt(this.parent.style.paddingBottom) || 0);
      };

      Affixable.prototype.getTop = function() {
        return parseInt(window.pageYOffset + this.el.getBoundingClientRect().top - this.topStyle);
      };

      Affixable.prototype.getTopStyle = function() {
        return parseInt(this.el.style.top) || 0;
      };

      Affixable.prototype.refreshClasses = function() {
        var scrollTop = window.pageYOffset;
        var scrollBottom = scrollTop + window.innerHeight;
        // if the top of the window is past the top of the element
        if (scrollTop >= this.top) {
          if (!this.el.classList.contains('affix')) {
            this.el.classList.add('affix');
          }
          // if there is a bottom
          if (this.maxY) {
            // if the bottom of the window is past the bottom of the container,
            // and the top of the window is past the maximum point the top of the
            // element can be at
            if (scrollBottom > this.maxY && scrollTop > this.getBottom()) {
              if (!this.el.classList.contains('affix-bottom')) {
                this.el.classList.add('affix-bottom');
              }
            } else if (this.el.classList.contains('affix-bottom')) {
              this.el.classList.remove('affix-bottom');
            }
          }
        } else if (this.el.classList.contains('affix')) {
          this.el.classList.remove('affix');
        }
      };

      Affixable.prototype.setMaxY = function() {
        this.maxY = this.getMaxY();
      };

      return Affixable;
    })(),
    init: function(){
      // TODO: what does this do?
      if ($('#scroll-nav').length){
        $('body').scrollspy('refresh');
      }

      [].slice.call(document.querySelectorAll('.affixable')).forEach(function(el) {
        var affixObject = new this.affixable(el);
        affixObject.refreshClasses();
        this.affixables.push(affixObject);
      }.bind(this));
      this.registerListeners();
      this.initialized = true;
    },
    initialized: false,
    registerListeners: function() {
      if (window.addEventListener) {
        window.addEventListener('scroll', this.refreshClasses.bind(this));
      } else {
        window.attachEvent('onscroll', this.refreshClasses.bind(this));
      }
    },
    reInit: function(){
      // reinitialize all cached properties on affixables. useful after major dom rearrangement
      this.affixables = this.affixables.map(function(affixObject) {
        return new this.affixable(affixObject.el);
      }.bind(this));

      this.refreshClasses();
    },
    refreshClasses: function() {
      this.affixables.forEach(function(affixObject) { affixObject.refreshClasses(); });
    }
  };

  /**
   * ScrollNav
   */
  function ScrollNav() {
    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollEnd = this.handleScrollEnd.bind(this);

    // state properties
    this.activeLinkId;
    this.isBusy;
    this.isListening;
  }

  ScrollNav.prototype.init = function(firstTime) {
    var links = [].slice.call(document.querySelectorAll("#scroll-nav a"));

    this.sections = links.map(function(el) {
      var section = document.querySelector(el.hash);
      if (section) {
        /* Setting scroll offset 10px higher to indicate accurately the current
        active link when there are small discrepancies */
        return {id: section.id, offset: (getOffset(section).top - 10)};
      }
    })
    .filter(function(section) { return !!section; })
    .sort(function(sectionA, sectionB) { return sectionA.offset - sectionB.offset; });

    if (firstTime) {
      this.isBusy = false;
      this.addScrollListener();

      links.forEach(function(el) {
        el.addEventListener('click', this.handleClick);
      }.bind(this))
    }

    this.handleScroll();
  };

  ScrollNav.prototype.handleClick = function(e) {
    if (this.isBusy) {
      e.preventDefault();
      return;
    }

    var targetId = e.currentTarget.hash.substring(1);

    var targetOffset = this.sections.reduce(function(acc, section) {
      if (acc !== null) return acc;
      return (section.id === targetId) ? section.offset : acc;
    }, null);

    if (targetOffset !== null) {
      e.preventDefault();
      this.isBusy = true;
      this.setActiveLink(targetId);

      this.removeScrollListener();

      // Hster.Utils.smoothScroll(targetOffset, 500, this.handleScrollEnd);

      // Temporary solution to fix lazy embeds' messing with initial scroll offsets
      window.location.hash = targetId;
      this.handleScrollEnd();
    }
  };

  ScrollNav.prototype.handleScroll = function() {
    if (this.sections.length === 0) return;

    var scrollPosition = getDocumentElement().scrollTop;

    var id = this.sections.reduce(function(acc, section) {
      if (section.offset <= scrollPosition) {
        return section.id;
      }
      return acc;
    }, this.sections[0].id);

    if (id !== this.activeLinkId) {
      this.setActiveLink(id);
    }
  };

  ScrollNav.prototype.handleScrollEnd = function() {
    this.addScrollListener();
    this.isBusy = false;
  };

  ScrollNav.prototype.addScrollListener = function() {
    if (this.isListening) return; // memory leak safeguard

    window.addEventListener('scroll', this.handleScroll);
    this.isListening = true;
  };

  ScrollNav.prototype.removeScrollListener = function() {
    window.removeEventListener('scroll', this.handleScroll);
    this.isListening = false;
  };

  ScrollNav.prototype.setActiveLink = function(id) {
    this.activeLinkId = id;
    document.querySelector('#scroll-nav .active').classList.remove('active');
    document.querySelector('#scroll-nav a[href$=' + id + ']').parentElement.classList.add('active');
  };

  /**
   * Offset relative to document using scrollTop/scrollLeft, so result will be integers.
   * Calculated differently from jQuery offset method, which can return floats.
   * source: https://stackoverflow.com/a/442474/1389981
   */
  function getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN( el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  }

  function getDocumentElement() {
    return document.documentElement || document.body;
  }

  window.addEventListener('load', function(event) {
    if (document.getElementById('scroll-nav')) {
      var scrollNav = new ScrollNav();
      scrollNav.init(true);

      var reInit = lodashDebounce(function() { scrollNav.init(false); }, 300);
      $('.middle-column').resize(reInit);
    }
  });
})(window);
